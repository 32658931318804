import ErrorHandlingModal from './components/ErrorHandlingModal';
import Loader from './components/Loader';
import { AppProvider, useAppContext } from './context/App';
import Providers from './providers';

export default function Layout({ children }: { children: React.ReactNode }) {
    const {
        globalErrors
    } = useAppContext();

    console.log(globalErrors);

    return (
        <Providers>
            {/* Screen is 900px wide by 1300px in height */}
            <div className='w-[900px] mx-auto relative h-[1600px]'>
                <AppProvider>
                    {children}
                    <ErrorHandlingModal />
                </AppProvider>
            </div>

            <Loader />
        </Providers>
    );
}
