import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of your context
interface AppContextType {
    requestId: string | null;
    setRequestId: (id: string) => void;
    orderId: string | null;
    setOrderId: (id: string) => void;
    globalErrors: any;
    setGlobalErrors: (error: any) => void;
}

// Create the AppContext with default values
const AppContext = createContext<AppContextType>({
    requestId: null,
    setRequestId: () => {},
    orderId: null,
    setOrderId: () => {},
    globalErrors: null,
    setGlobalErrors: () => {},
});

// Custom hook to use the AppContext
export const useAppContext = () => useContext(AppContext);

// Create a provider component
export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [requestId, setRequestId] = useState<string | null>(null);
    const [orderId, setOrderId] = useState<string | null>(null);
    const [globalErrors, setGlobalErrors] = useState<any>([]);

    console.log(globalErrors);

    return (
        <AppContext.Provider 
            value={{ 
                requestId, 
                setRequestId,
                orderId,
                setOrderId,
                globalErrors, 
                setGlobalErrors
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
