import { useMutation } from "@tanstack/react-query";
import { FC, useState } from "react";

import { applyDiscount } from "../../../api/applyDiscount";

import { useErrorHandling } from "../../../hooks/useErrorHandling";
import { useGetCart } from "../../../hooks/useGetCart";
import { useGetTheme } from "../../../hooks/useGetTheme";

import { ApplicableVoucher } from "../../../types/types";

import Modal from "../../Modal";

interface VoucherModalInterface {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const VoucherModal: FC<VoucherModalInterface> = ({show, setShow}) => {
    const theme = useGetTheme();
    const cart = useGetCart();
    const [appliedVouchers, setAppliedVouchers] = useState<number[]>(
        cart?.cart.appliedVouchers.map(voucher => voucher?.voucherIdentifierRoleId).filter((id): id is number => typeof id === 'number') ?? []
    );

    const {
        addError
    } = useErrorHandling();
    // const [hasPopulatedVouchers, setHasPopulatedVouchers] = useState(false);

    // TODO
    // const vouchers = [{
    //     title: '£1.00 off any breakfast item',

    // }, {
    //     title: 'Free coffee with a slice of cake'
    // }, {
    //     title: '50% off sandwiches'
    // }];

    const vouchers = cart?.cart?.applicableVouchers;

    const applyDiscountApi = useMutation({
        mutationFn: applyDiscount,
        onSuccess: (data) => {
            if (data?.errorHandling?.success) {

            }

            addError(data?.errorHandling);
        },
        onError: (err) => {
            // handleCatchErrors(err, 'app.js - stratus21SetOrderType - Error');
        }
    });

    if(!vouchers || !vouchers.length) return null;

    // Populate the carts selected vouchers
    // if (
    //     !appliedVouchers.length &&
    //     cart?.cart.appliedVouchers &&
    //     cart?.cart.appliedVouchers.length &&
    //     !hasPopulatedVouchers
    // ) {
    //     const voucherIds = cart?.cart.appliedVouchers.map(voucher => voucher?.voucherIdentifierRoleId).filter((id): id is number => typeof id === 'number') ?? [];
        
    //     setAppliedVouchers(voucherIds);
    //     setHasPopulatedVouchers(true);
    // }

    return (
        <Modal
            show={show}
            setShow={setShow}
            width={700}
        >
            <p className='font-bold mb-8 text-center text-[29px]'>Select Vouchers:</p>
            
            {vouchers && vouchers.length ? (
                <>
                    {vouchers.map((voucher: ApplicableVoucher, index) => (
                        <div className="flex mb-6">
                            <input 
                                type="checkbox" 
                                className="mr-5"
                                checked={appliedVouchers.includes(voucher?.voucherIdentifierRoleId) ? true : false}
                                id={`voucher_${index}`}
                                onClick={() => {
                                    if (!appliedVouchers.includes(voucher?.voucherIdentifierRoleId)) {
                                        setAppliedVouchers([...appliedVouchers, voucher?.voucherIdentifierRoleId]);
                                        return;
                                    } 
                                    
                                    let newVouchers = appliedVouchers.filter((value: number) => value !== voucher.voucherIdentifierRoleId);

                                    setAppliedVouchers(newVouchers);
                                }}
                            /> 
                            <label 
                                className="text-3xl" 
                                htmlFor={`voucher_${index}`}
                            >{voucher.description}</label>
                        </div>
                    ))}
                </>
            ) : null}

            <div className='flex'>
                <button 
                    className='btn btn-small-spacing btn-body mr-4 w-1/2' 
                    onClick={() => {
                        setShow(false);
                    }}
                >
                    Cancel
                </button>

                <button 
                    className='btn btn-small-spacing w-1/2'
                    style={{
                        backgroundColor: theme.secondaryColor,
                        color: '#FFFFFF'
                    }}
                    onClick={() => {
                        applyDiscountApi.mutate({
                            cartId: cart?.cart.id ?? '',
                            applyPoints: cart?.cart.isLoyaltyApplied ?? false,
                            voucherIDs: appliedVouchers
                        });
                    }}
                >
                    Apply
                </button>
            </div>
        </Modal>
    );
}

export default VoucherModal;