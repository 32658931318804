import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import fetchDojoTerminals from '../api/fetchDojoTerminals';
import makeDojoPayment from '../api/makeDojoPayment';
import { useAppContext } from '../context/App';
import { DojoConfig } from '../types/types';
import { setDojoKioskTransaction } from '../api/setDojoKioskTransaction';
import { setPlaceOrder } from '../api/placeOrder';
import { useGetCart } from './useGetCart';
import { useErrorHandling } from './useErrorHandling';
import { cancelPlaceOrder } from '../api/cancelPlaceOrder';

interface TerminalData {
    id: string;
    name: string;
    status: string;
    // Add more fields as necessary based on the API response
}

interface PaymentResponse {
    requestId: string;
    location: string;
    userMessage: string;
}

interface TransactionStatusResponse {
    amountBase: string;
    amountCashback: string;
    amountGratuity: string;
    amountTotal: string;
    applicationId: string;
    applicationLabel: string;
    authCode: string;
    cardSchemeName: string;
    cardHolderVerificationMethod: string;
    currency: string;
    dateOfExpiry: string;
    dateOfStart: string;
    location: string;
    notifications: string[];
    paymentMethod: string;
    primaryAccountNumber: string;
    primaryAccountNumberSequence: string;
    receiptLines: {
        MERCHANT: Array<{
            format: string[];
            type: string;
            value: string;
        }>;
        CUSTOMER: Array<{
            format: string[];
            type: string;
            value: string;
        }>;
    };
    requestId: string;
    transactionId: string;
    transactionNumber: string;
    transactionResult: string;
    transactionTime: string;
    transactionType: string;
    userMessage: string;
}

export const useDojo = () => {
    const { 
        orderId,
        setOrderId,
        setRequestId, 
        requestId
    } = useAppContext();

    const {
        addError
    } = useErrorHandling();

    const navigate = useNavigate();
    const queryClient = useQueryClient(); 
    const cart = useGetCart();

    const accountName = 'sc410y120000';
    const terminalState = 'AVAILABLE';
    const env = 'test.connect.paymentsense.cloud';

    const dojoConfig: DojoConfig = {
        url: `https://${accountName}.${env}`,
        key: 'd54c538a-9d09-46e0-a4ae-a45ad15031c3',
        username: 'DevTest',
        houseId: 'SC410Y12',
        installerId: '1',
        currentTerminalId: '21110772',
    };

    const headers = new Headers();
    const basicAuth = btoa(`${dojoConfig.username}:${dojoConfig.key}`);

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Basic ' + basicAuth);
    headers.append('Software-House-ID', dojoConfig.houseId);
    headers.append('Installer-ID', dojoConfig.installerId);
    headers.append('Accept', 'application/connect.v2+json');

    // Fetch list of terminals
    const { data: terminals } = useQuery<TerminalData[], Error>({
        queryKey: ["terminals", terminalState],
        queryFn: () => fetchDojoTerminals(dojoConfig, headers, terminalState),
        onSuccess: (data) => {
            // TODO HANDLE
            // addError(data?.errorHandling);
        },
        onError: (err) => {
            console.error("Error fetching terminals:", err);
        }
    });

    // Make payment API - Pass in terminalId and amount
    const makePaymentApi = useMutation<PaymentResponse, Error, {
        amount: number 
    }>({
        mutationFn: ({ amount }) => makeDojoPayment(amount, dojoConfig, headers),
        onSuccess: (data) => {
            setRequestId(data?.requestId ?? ''); // Store the requestId
            navigate('/payment');
        },
        onError: (err) => {
            console.error("Error making payment:", err);
            // handleCatchErrors can be added here if needed
        }
    });

    // This validates order before making dojo payment
    const placeOrderApi = useMutation({
        mutationFn: setPlaceOrder,
        onSuccess: (data) => {
            if(data.errorHandling.success) {
                setOrderId(data?.order?.order_id);
                makePaymentApi.mutate({
                    amount: cart?.cart?.prices?.grand_total.value ?? 0.00
                });
            }
            
            console.log(data, "heeeere");
            addError(data.errorHandling);
        },
        onError: (err) => {
            console.log("err", err);
            addError(null, "ahhhhh a problem");
            // handleCatchErrors(err, "Place order api failed");
        }
    });

    const placeOrder = (redirect?: string) => {
        placeOrderApi.mutate({
            cartID: cart?.cart?.id ?? ''
        });
    }
    
    const setDojoTransactionApi = useMutation({
        mutationFn: setDojoKioskTransaction,
        onSuccess: (data) => {
            addError(data?.errorHandling);
            
            if (data?.errorHandling?.success) {
                navigate('/confirmed');
            }
        },
        onError: (err) => {
            // handleCatchErrors(err, 'app.js - stratus21SetOrderType - Error');
        }
    });

    const setCancelPlacedOrderApi = useMutation({
        mutationFn: cancelPlaceOrder,
        onSuccess: (data) => {
            addError(data?.errorHandling);
        },
        onError: (err) => {
            // handleCatchErrors(err, 'app.js - stratus21SetOrderType - Error');
        }
    });
    
    // Function to poll transaction status every second
    const { data: transactionStatus } = useQuery<TransactionStatusResponse>(
        [
            "transactionStatus", 
            dojoConfig.currentTerminalId, 
            requestId
        ],
        async () => {
            const endpoint = `pac/terminals/${dojoConfig.currentTerminalId}/transactions/${requestId}`;
            const response = await fetch(`${dojoConfig.url}/${endpoint}`, {
                method: 'GET',
                headers: headers,
            });
            if (!response.ok) { 
                throw new Error('Error fetching transaction status');
            }
            return await response.json();
        },
        {
            enabled: !!requestId,  // Only start polling if requestId is available
            refetchInterval: 1000,  // Poll every 1 second
            onSuccess: (data) => {
                console.log('Transaction Status:', data);
                if(data.transactionResult === 'SUCCESSFUL') {
                    // TODO
                    // Make stratus21 call and send data, then redirect and remove transaction result data ready for next order
                    queryClient.setQueryData(['transactionStatus'], (oldData: any) => {
                        return {
                            "location": "",
                            "notifications": []
                        };
                    });

                    // TODO - Add to state and ensure this clears on restartKiosk
                    // - Test and see if we can tweak cart info after placing order
                    // - Test payment failed to see if cart is locked or not.
                    if(orderId) {
                        setDojoTransactionApi.mutate({
                            orderId: orderId,
                            transactionId: data.transactionId
                        });

                        setRequestId('');
                        setOrderId('');
                    }
                }

                if (
                    ['CANCELLED', 'DECLINED', 'VOID', 'UNSUCCESSFUL'].includes(data.transactionResult) && 
                    orderId
                ) {
                    setCancelPlacedOrderApi.mutate({
                       orderId: orderId
                    });

                    setRequestId('');
                    setOrderId('');
                }
            },
            onError: (err) => {
                console.error('Error fetching transaction status:', err);
            }
        }
    );

    return {
        terminals,
        makePaymentApi,
        placeOrder,
        transactionStatus,
    };
}
