import { ERROR_HANDLING } from '../queries/errorHandling';
import { fetcher } from './fetcher';

// Clean up server response (It was returning data.data.cart.cart etc)
function responseCleanup(data: any) {
    return data.data.stratus21CancelMagentoTcpOrder;
}

interface CancelPlaceOrderInterface {
    orderId: string;
}

export async function cancelPlaceOrder(payload: CancelPlaceOrderInterface) {
    return await fetcher(
        `
        mutation {
            stratus21CancelPlaceOrder(
                orderId: "${payload.orderId}"
            ) {
                status
                ${ERROR_HANDLING}
            }
        }
        `,
        responseCleanup
    );
}