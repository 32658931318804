import { useQuery } from "@tanstack/react-query";
import fetchTables from "../api/fetchTables";
import { TableInterface } from "../types/types";
import { useErrorHandling } from "./useErrorHandling";

export const useGetTables = () => {
    const payload = {
        storeId: 2
    };

    const {
        addError
    } = useErrorHandling();
    
    const { data: tables } = useQuery<TableInterface>({
        queryKey: ["tables", payload],
        queryFn: () => fetchTables(payload),
        onSuccess: (data) => {
            addError(data?.errorHandling);
        },
        onError: (err) => {
            // handleCatchErrors(err, "fetchCustomer api failed");
        }
    });

    return tables ?? {
        seating: []
    };
};
