import { FC, useState, useEffect, useRef } from "react";
import { useRestartKiosk } from "../../hooks/restartKioskSession";
import { useGetTheme } from "../../hooks/useGetTheme";
import Modal from "../Modal";

export const TimeoutModal: FC = () => {
    const theme = useGetTheme();
    const defaultTime = 30; // 30 seconds countdown
    const showModalIn = 60000 * 5; // 5 mins
    const [show, setShow] = useState(false);
    const [timeLeft, setTimeLeft] = useState(defaultTime);
    const { restartKiosk } = useRestartKiosk();

    // Refs to hold the timeout/interval ids to clear them when necessary
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    // Function to show the modal after inactivity (2 mins)
    const startTimeoutToShowModal = () => {
        clearExistingTimeouts();
        timeoutRef.current = setTimeout(() => {
            setShow(true);
        }, showModalIn);
    };

    // Function to clear any existing timers/intervals
    const clearExistingTimeouts = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    // Countdown logic when modal is shown
    const startCountdown = () => {
        intervalRef.current = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(intervalRef.current!); // Stop countdown
                    restartKiosk(); // Kiosk session restart
                    return defaultTime;
                }
                return prevTime - 1;
            });
        }, 1000); // 1-second countdown
    };

    // Function to handle user activity (reset the 2-minute inactivity timer)
    const handleUserActivity = () => {
        if (!show) {
            clearExistingTimeouts();
            startTimeoutToShowModal(); // Reset the inactivity timer
        }
    };

    // Attach global event listeners to detect user activity
    useEffect(() => {
        // Function to handle user activity but only if the modal is not shown
        const handleUserActivityIfModalNotShown = () => {
            if (!show) {
                handleUserActivity();
            }
        };
    
        const events = ['click', 'scroll', 'keydown', 'mousemove'];
    
        // Attach the event listeners
        events.forEach(event =>
            window.addEventListener(event, handleUserActivityIfModalNotShown)
        );
    
        // Cleanup event listeners on component unmount
        return () => {
            events.forEach(event =>
                window.removeEventListener(event, handleUserActivityIfModalNotShown)
            );
            clearExistingTimeouts();
        };
    }, [show]); // Dependency on 'show' state to re-run the effect when modal state changes
    

    // Effect to start countdown when the modal is shown
    useEffect(() => {
        if (show) {
            setTimeLeft(defaultTime); // Reset countdown
            startCountdown();
        }
    }, [show]);

    // Effect to start the inactivity timer on component mount
    useEffect(() => {
        startTimeoutToShowModal();
        return () => {
            clearExistingTimeouts(); // Cleanup on component unmount
        };
    }, []);

    // Handle closing the modal and resetting timers
    const handleCloseModal = () => {
        clearExistingTimeouts();
        setShow(false);
        setTimeLeft(defaultTime);
        startTimeoutToShowModal(); // Restart the inactivity timer
    };

    if (!show) return null;

    return (
        <Modal
            show={true}
            setShow={setShow}
        >
            <p className="font-bold mb-6 text-center text-[29px]">
                Are you still ordering?
            </p>
            <p className="text-center mb-6">
                This session will automatically end in<br />
                <strong>{timeLeft}</strong> seconds if left inactive...
            </p>

            <div className="flex">
                <button
                    className="btn btn-small-spacing btn-danger mr-4 w-1/2"
                    onClick={restartKiosk}
                >
                    Cancel Order
                </button>

                <button
                    className="btn btn-small-spacing w-1/2 text-white"
                    style={{
                        backgroundColor: theme.secondaryColor
                    }}
                    onClick={handleCloseModal}
                >
                    Yes
                </button>
            </div>
        </Modal>
    );
};

export default TimeoutModal;
