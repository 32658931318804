import { useAppContext } from "../context/App";
import { ApiErrorInterface } from "../types/types";

export const useErrorHandling = () => {
    const {
        globalErrors, 
        setGlobalErrors
    } = useAppContext();

    function addError(error: ApiErrorInterface | null, message?: string) {
        let errors = globalErrors;

        if(error) {
            console.log("yes 1");
            if(error?.message && error?.message.length) {
                console.log("yes 2");

                error?.message.forEach((message, messageIndex) => {
                    errors.push({
                        id: errors.length + (messageIndex + 1),
                        type: message.type,
                        message: message.message,
                    });
                });
            }
            console.log("yes 3", errors);

            setGlobalErrors(errors);

            return;
        }

        if(message) {
            console.log("yes 4", errors);

            errors.push({
                id: errors.length + 1,
                type: 'error',
                message: message,
            });

            setGlobalErrors(errors);
        }

        console.log(errors, "new errors");
    }

    function clearErrors() {
        setGlobalErrors([]);
    }

    return {
        addError,
        clearErrors
    }
};
