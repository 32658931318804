import { FC } from "react";
import { ProductWrapper } from "../context/Product";
import { MenuProduct } from "../types/types";
import AddToCartButton from "./Menu/AddToCartButton"; 

import Modal from "./Modal";
import ProductInputs from "./ProductModal/ProductInputs";

interface RemoveItemModalInterface {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    productDetails: MenuProduct;
}

export const ProductModal: FC<RemoveItemModalInterface> = ({ 
    productDetails,
    show, 
    setShow
 }) => {

    if(!productDetails?.name) return null;

    return (
        <Modal
            show={show}
            setShow={setShow}
            nopadding
        >
            <ProductWrapper productDetails={productDetails}>
                {(productDetails?.productImages && productDetails?.productImages.length) ? (
                    <div
                        className="w-full h-[470px] relative mb-8"
                        style={{
                            backgroundImage: `url(${productDetails?.productImages[0].src})`,
                            backgroundSize: "cover"
                        }}
                    >
                        <img src="hero-curve.png" alt="Curve" className="absolute bottom-0 "/>
                    </div>
                ) : null}

                <div className="flex flex-col px-14 overflow-y-auto pb-8">
                    <div className="mb-10">
                        <div className="flex justify-between mb-3">
                            <p className="font-bold text-4xl">{productDetails?.name}</p>
                            <p className="font-bold text-4xl">{productDetails?.priceFormatted}</p>

                            {!productDetails?.inStock ? (<span className="text-red-500 my-2 text-sm">Out of stock</span>) : null}
                        </div>

                        <p className="font-bold mb-3">{productDetails.calorificContent}</p>
                        <p className="mb-6">{productDetails?.longDescription}</p>

                        {/* Allergy */}
                        {productDetails?.allergyShort || productDetails?.allergyLong ? (
                            <p><strong>Allergy Information:</strong></p>
                        ) : null}

                        {productDetails?.allergyShort && (
                            <p className="mb-2">{productDetails?.allergyShort}</p>
                        )}

                        {productDetails?.allergyLong && (
                            <p className="mb-2">{productDetails?.allergyLong}</p>
                        )}

                        {productDetails?.allergens && productDetails?.allergens.length ? (
                            <div className="flex">
                                {productDetails?.allergens.map((allergen) => {
                                    return (
                                        <div className="tag bg-tag text-tag mr-2">{allergen.value}</div>        
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                    
                    <ProductInputs
                        editData={[]}
                    />
                </div>

                <div 
                    className="bg-slate-100 flex px-14 py-8"
                >
                    <button
                        className="btn w-1/2 mr-8"
                        style={{
                            backgroundColor: '#CBD5E1'
                        }}
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        Cancel
                    </button>

                    <AddToCartButton
                        setShow={setShow}
                    />
                </div>
            </ProductWrapper>
        </Modal>
    )
};

export default ProductModal;